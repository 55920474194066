
import React from "react";
import { useTranslation, Trans } from "react-i18next"
import { Link } from "gatsby"
import MainLayout from "../../layouts/MainLayout";
import "./cookie.scss";

const Cookie = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <>
        <div className="cookie">
          <div>
            <h1 className="box-three">{t("cookie:cookieTitle")}</h1>

            <h2 className="box-two">{t("cookie:cookie1")}</h2>

            <p className="box-two">{t("cookie:cookie2")}</p>

            <p className="box-two">
              <Trans i18nKey='cookie:cookie3'>
                part1
                <Link to={t("routes:terms")} className="decoration"></Link>
                <span className="decoration"></span>
                part2
              </Trans>
            </p>

            <p className="box-two">{t("cookie:cookie4")}</p>

            <p className="space">
              <Trans i18nKey='cookie:cookie5'>
                part1<i></i>
              </Trans>
            </p>
            <p className="space">
              <Trans i18nKey='cookie:cookie6'>
                part1<i></i>
              </Trans>
            </p>
            <p className="space">
              <Trans i18nKey='cookie:cookie7'>
                part1<i></i>
              </Trans>
            </p>
            <p className="space">
              <Trans i18nKey='cookie:cookie8'>
                part1<i></i>
              </Trans>
            </p>
            <p className="space">
              <Trans i18nKey='cookie:cookie9'>
                part1<i></i>
              </Trans></p>
            <p className="space">
              <Trans i18nKey='cookie:cookie10'>
                part1<i></i>
              </Trans></p>
            <p className="space box-two">
              <Trans i18nKey='cookie:cookie11'>
                part1<i></i><a className="decoration" href="mailto:info@gorda.rs" />
              </Trans>
            </p>

            <p>{t("cookie:cookie12")}</p>
            <p className="box-two">{t("cookie:cookie13")}</p>

            <p className="box-two">
              <Trans i18nKey='cookie:cookie14'>
                part1
                <Link to={t("routes:terms")} className="decoration"></Link>
                <span className="decoration"></span>
                part2
              </Trans>
            </p>

            <p className="box-two">{t("cookie:cookie15")}</p>

            <p className="box-two">{t("cookie:cookie16")}</p>

            <h2 className="box-two">{t("cookie:cookie17")}</h2>

            <p className="box-two">
              <Trans i18nKey='cookie:cookie18'>
                part1
                <Link to='/'></Link>
              </Trans>
              </p>

            <p>{t("cookie:cookie19")}</p>
            <ul>
              <li>{t("cookie:cookie20")}</li>
              <li>{t("cookie:cookie21")}</li>
              <li className="box-two">{t("cookie:cookie22")}</li>
            </ul>

            <p className="box-two">{t("cookie:cookie23")}</p>

            <p>{t("cookie:cookie24")}</p>
            <ul>
              <li>
              <Trans i18nKey='cookie:cookie25'>
                part1
                <span className="decoration"></span>
              </Trans>
              </li>
              <li>{t("cookie:cookie26")}</li>
              <li>{t("cookie:cookie27")}</li>
              <li>{t("cookie:cookie28")}</li>
              <li>{t("cookie:cookie29")}</li>
              <li className="box-two">{t("cookie:cookie30")}</li>
            </ul>

            <p>{t("cookie:cookie31")}</p>
            <ul>
              <li>{t("cookie:cookie32")}</li>
              <li className="box-two">{t("cookie:cookie33")}</li>
            </ul>

            <p className="box-two">{t("cookie:cookie34")}</p>

            <p className="box-two">{t("cookie:cookie35")}</p>

            <h2 className="box-two">{t("cookie:cookie36")}</h2>

            <p>{t("cookie:cookie37")}</p>

            <p className="box-two">{t("cookie:cookie38")}</p>

            <p>{t("cookie:cookie39")}</p>
            <ul>
              <li>{t("cookie:cookie40")}</li>
              <li>{t("cookie:cookie41")}</li>
              <li>{t("cookie:cookie42")}</li>
              <li>{t("cookie:cookie43")}</li>
              <li>{t("cookie:cookie44")}</li>
            </ul>
            <p>{t("cookie:cookie45")}</p>
            <p className="box-two">{t("cookie:cookie46")}</p>

            <h4 className="box-two">{t("cookie:cookie47")}</h4>

            <p className="box-two">{t("cookie:cookie48")}</p>

            <p>{t("cookie:cookie49")}</p>
            <p>{t("cookie:cookie50")}</p>
            <p className="box-two">{t("cookie:cookie51")}</p>
           
            <h4 className="box-two">{t("cookie:cookie52")}</h4>

            <p className="box-two">{t("cookie:cookie53")}</p>

            <p className="box-two">{t("cookie:cookie54")}</p>

            <p>{t("cookie:cookie55")}</p>
            <p className="box-two">{t("cookie:cookie56")}</p>

            <p className="box-two">{t("cookie:cookie57")}</p>

            <p>{t("cookie:cookie58")}</p>
            <p>{t("cookie:cookie59")}</p>
            <p>{t("cookie:cookie60")}</p>
            <p>{t("cookie:cookie61")}</p>
            <p className="box-two">{t("cookie:cookie62")}</p>
            <h2 className="box-two">{t("cookie:cookie63")}</h2>

            <p className="box-two">{t("cookie:cookie64")}</p>

            <p className="box-two">{t("cookie:cookie65")}</p>

            <h2 className="box-two">{t("cookie:cookie66")}</h2>

            <p className="box-two">{t("cookie:cookie67")}</p>

            <p>{t("cookie:cookie68")}</p>

          
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default Cookie;
